import $ from 'jquery';
import loader from './loader';

export default async;

export function async (url, data, cb, loaderElement) {
  if (typeof data === 'function') {
    cb = data;
  }

  const _loader = loader('body', 500, loaderElement);

  ajax(url, data, function (payload) {
    clearTimeout(_loader);

    if (typeof cb === 'function') {
      cb(payload);
    }
  });
}

export function redraw (url, data, targets, cb, loaderElement) {
  if (typeof targets === 'function') {
    targets = 'body';
    cb = targets;
  }

  const _loader = loader(targets, 500, loaderElement);

  ajax(url, data, function (payload) {
    clearTimeout(_loader);

    for (const id in payload) {
      if (payload.hasOwnProperty(id)) {
        const target = $('#' + id);
        if (target.length) {
          target.html(payload[id]);

          // if ((typeof CKEDITOR !== 'undefined') &&
          //   CKEDITOR.instances.hasOwnProperty(id)) {
          //   CKEDITOR.instances[id].setData(payload[id]);
          // }
          // else {
          //   target.html(payload[id]);
          // }
        }
      }
    }

    if (typeof cb === 'function') {
      cb(payload);
    }
  });
}

class AjaxException {
  constructor (message, context) {
    this.name = 'SMWeb ajax exception';
    this.message = message;

    if (typeof context !== 'undefined') {
      this.context = context;
    }
  }

  toString () {
    return this.name + ': ' + this.message;
  }
}

function ajax (url, data, cb) {
  if (!url) {
    throw new AjaxException('No URL provided!');
  }

  data = data || {};

  $.ajax({ url, data })
    .done(function (payload) {
      if (!payload.hasOwnProperty('status')) {
        throw new AjaxException(
          'Unknown response status! Do include a status in your response.',
          payload
        )
      }

      if (isResponseFormatInvalid(payload)) {
        throw new AjaxException(
          'Unknown response message! Do include a message in your response when status is danger or warning.',
          payload
        )
      }

      if (typeof cb === 'function') {
        cb(payload);
      }

      if (payload.hasOwnProperty('location')) {
        window.location = payload.location;
      }
    })
    .fail(function (e) {
      throw new AjaxException('Error calling URL: ' + url, e);
    })
}

function isResponseFormatInvalid (payload) {
  return (
    (payload.status === 'danger' || payload.status === 'warning') &&
    !payload.hasOwnProperty('message')
  )
}
