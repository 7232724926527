import $ from 'jquery';

require('../../sass/admin/components/loader.scss');

export default loader;

export function loader (targets = 'body', timeout = 500, loaderElement) {
  loaderElement =
    loaderElement || '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>';

  return setTimeout(function () {
    $(targets).each(function () {
      if ($(this).css('position') === 'static') {
        $(this).css({ position: 'relative' });
      }

      $(this).append(loaderElement);
    })
  }, timeout);
}
